import { SEO } from 'components/blocks/seo';
import Mdx from 'components/containers/mdx';
import styles from 'components/pages/our-beliefs/our-beliefs.module.scss';
import { CTA } from 'components/shared/cta';
import { PageInfo } from 'components/shared/page-info';
import { graphql } from 'gatsby';
import { DefaultLayout } from 'layouts/default-layout';
import React from 'react';
import SEO_DATA from 'utils/seo-metadata';
import { docs } from 'utils/urls';

const OurBeliefs = ({ data }) => {
  const { body: content, headings } = data.file.children[0];

  return (
    <DefaultLayout>
      <PageInfo
        title={'Developer-centric load testing'}
        description={'Manifesto'}
        className={styles.pageInfo}
        withBgPattern
      />
      <Mdx content={content} mode={'withToc'} className={styles.contentWrapper} headings={headings} />
      <CTA
        title={'Are you ready to try k6?'}
        description={'Join the growing k6 community and start a new way of load testing.'}
        buttonText={'Get Started'}
        buttonRef={docs}
        themeLight
      />
    </DefaultLayout>
  );
};

export const query = graphql`
  query {
    file(name: { eq: "our-beliefs" }, relativeDirectory: { eq: "markdown" }) {
      children {
        ... on Mdx {
          body
          headings {
            depth
            value
          }
        }
      }
    }
  }
`;

export default OurBeliefs;

export const Head = () => <SEO {...SEO_DATA['our-beliefs']} />;
